@import "../../../../utils/vars.scss";

.person-base-layout {
    width: 100%;
    background: none;

    .row-steps-content {
        width: 100%;
        flex-wrap: initial;

        @media (max-width: $sm_max) {
            flex-wrap: wrap;

            .steps-card {
                display: none;
            }
        }
    }
    .person-title {
        color: var(--green);
    }
    .titulo-desc {
        margin-bottom: 10px;

        @media (max-width: $sm_max) {
            text-align: center;
        }
    }

    .subtitle {
        color: var(--green);
        font-weight: 600;
        margin-bottom: 8px;
    }
}
