.loading-data {
  padding-top: 128px;
  height: calc(100vh - 170px);
  justify-content: center;
  text-align: center;
  .ant-typography {
    color: var(--green);
  }

  .loading-icon {
    font-size: 120px;
    color: var(--green);
    margin-bottom: 32px;
  }
}
