@import "../../../utils/vars.scss";

.ant-layout-footer {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  width: 100%;
  height: auto !important;
  padding: 0 16px;
  font-size: 16px;
  font-family: Roboto !important;
}

.background-efexhub-footer {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  padding: 0;

  .long-text {
    width: 70%;
  }

  @media (max-width: $sm_max) {
    .long-text {
      width: 100%;
    }
  }

  >.ant-row {
    background-color: #2c2c2c;
    width: 100%;

    >.ant-col {
      padding: 20px !important;
      background-color: #2c2c2c;
    }

    @media (max-width: $sm_max) {
      >.ant-col {
        padding: 15px 60px !important;
      }
    }
  }

  .footer-logo {
    height: 45px;
    width: auto;
    background-image: url("../../../common/assets/efexhub_white_logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 20px;
  }
}