.cant-register-modal {
	.ant-modal-body {
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			margin-bottom: 8px;
			opacity: 20%;
		}
	}

    .ant-modal-footer {
        .ant-btn:nth-child(1) {
            display: none;
        }
    }
}
