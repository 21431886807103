.whatsapp-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: white;
    border-radius: 100%;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
    z-index: 1000;
}

.whatsapp-icon:hover {
    background-color: #128c7e;
    color: white;
}

.whatsapp-icon svg {
    font-size: 30px;
}

@media (max-width: 767px) {
    .whatsapp-icon {
        padding: 15px;
    }

    .whatsapp-icon svg {
        font-size: 24px;
    }
}