@import "../../utils/vars.scss";

.finished-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 10px;

    @media (max-width: $sm_max) {
        text-align: center;
    }

    .show-icon {
        padding: 40px 0;

        svg {
            margin-top: 20px;
            margin-bottom: 20px;
            width: 150px;
            height: auto;
            color: var(--green);
        }
    }

    .description {
        font-size: 18px;
        max-width: 600px;
        text-align: center;
    }

    &.person {
        .ant-typography {
            color: var(--green);
        }
    }
}