@import "../../utils/vars.scss";

.legal-person {
    .row-empresa-endereco {
        flex-wrap: initial;
        padding-top: 32px;
        margin-right: 0 !important;

        @media (max-width: $sm_max) {
            flex-wrap: wrap;
        }

        .ant-col {
            width: 100%;
        }
    }
    .observacao {
        .obs {
            margin: 0;
            margin-bottom: 8px;
        }
    }
    .btn-back-next {
        width: 100%;
        margin-top: 20px;
        text-align: right;
    }
}

// .legal-person .ant-row {
//     flex-flow: initial;
// }
