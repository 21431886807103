$xs-screen: 574px;
$sm-screen: 766px;

.send-values {
    .white-button-green-hover {
        width: 120px;
    }
}
.switch-currency {
    width: 100%;
    max-width: 194px;
    text-align: center;
}

.actions-transaction {
    .ant-space-horizontal .ant-space-item {
        button {
            width: 140px;
        }
    }

    @media (max-width: $sm-screen) {
        .ant-space-horizontal {
            width: 100%;
            flex-direction: column;
            text-align: center;

            .ant-space-item {
                width: 100%;
                button {
                    width: 70%;
                }
            }
        }
    }
}

.input-value-exchange {
    width: 100%;
    height: 35px;
    font-size: 19px;
}

.ant-timeline-item-last {
    padding-bottom: 0;
}

.efex-upload-file-button {
    .ant-upload-list-text-container {
        padding: 5px 0;
    }
    .ant-upload-text-icon {
        display: none;
    }
    .ant-upload-list-item-name {
        color: var(--gray-dark) !important;
        padding: 0 !important;
        margin: 0 !important;
        margin-right: 10px !important;
        &:hover {
            text-decoration: underline !important;
        }
    }

    .ant-upload-span,
    .ant-upload-list-item-info,
    .ant-upload-list-item-list-type-text {
        padding: 0 !important;
        margin: 0 !important;
        background-color: transparent !important;
        &:hover {
            text-decoration: transparent !important;
        }
    }

    .ant-upload-list-item-card-actions-btn {
        font-weight: 400;
        font-size: 12px;
        color: red;
        background-color: transparent;
        box-shadow: none;
        border: None;

        &:focus,
        &:hover {
            color: red !important;
            background-color: transparent;
            box-shadow: none;
            border: None;
        }

        &:hover {
            opacity: 0.6 !important;
        }
        span {
            width: 15px !important;
        }
        span.anticon {
            svg {
                height: 15px;
                width: auto;
                color: red !important;
            }
        }
    }
}

.stretch-column {
    display: flex;
    align-items: first baseline;
    > div {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
    }
}

.switch-currency-btn {
    margin-bottom: 20px;
    cursor: "pointer";
    .arrow-switch-icon {
        &[data-disabled="true"] {
            opacity: 0.4;
        }
    }
}
