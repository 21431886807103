@import "../../../utils/vars.scss";

@media (max-width: $sm_max) {
    .table-card {
        overflow-x: scroll;

        >.ant-row {
            width: 100% !important;
            padding-bottom: 20px;

            >.ant-col {
                display: inline;
                float: left;
                height: 100%;
                width: 100%;
            }
        }
    }
}

@media (min-width: $sm_max) {
    .table-card {
        overflow-x: hidden;

        >.ant-row {
            width: 100% !important;
        }
    }
}

.multi-operation-card {
    border: 1px solid #bebebe;
    border-radius: 20px;
    width: 200px;
    height: 250px;

    @media (max-width: $sm_max) {
        width: 100%;
    }

    .selected-card {
        border: 2px solid #3498db;
        box-shadow: 0 0 10px rgba(52, 152, 219, 0.5);
        background-color: #ecf0f1;
    }

    .operation-card-header {
        border-bottom: 1px solid #bebebe;
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        justify-content: space-between;

        .operation-card-header-name {
            display: flex;
            align-items: center;
        }
    }

    .operation-card-body {
        padding-left: 10px;
        padding-right: 10px;

        .status {
            display: flex;
            align-items: center;
            font-size: 10px;
            color: #7b7b7b;

            .status-circle {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin-right: 5px;

                &.red {
                    background-color: red;
                }

                &.yellow {
                    background-color: orange;
                }

                &.green {
                    background-color: green;
                }
            }
        }
    }
}

.operation-card {
    border: 1px solid #bebebe;
    border-radius: 20px;
    width: 200px;
    height: 200px;

    @media (max-width: $sm_max) {
        width: 100%;
    }

    .selected-card {
        border: 2px solid #3498db;
        box-shadow: 0 0 10px rgba(52, 152, 219, 0.5);
        background-color: #ecf0f1;
    }

    .operation-card-header {
        border-bottom: 1px solid #bebebe;
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        justify-content: space-between;

        .operation-card-header-name {
            display: flex;
            align-items: center;
        }
    }

    .operation-card-body {
        padding-left: 10px;
        padding-right: 10px;

        .status {
            display: flex;
            align-items: center;
            font-size: 10px;
            color: #7b7b7b;

            .status-circle {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin-right: 5px;

                &.red {
                    background-color: red;
                }

                &.yellow {
                    background-color: orange;
                }

                &.green {
                    background-color: green;
                }
            }
        }
    }
}