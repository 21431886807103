@import "../../../utils/vars.scss";

.card-transaction {
    padding: 20px 15px;
    cursor: pointer;

    .transaction-info {
        @media (max-width: $sm_max) {
            padding: 0 16px;
            // display: flex;
            justify-content: space-between;
        }
    }

    .transaction-status-info {
        display: flex;
        justify-content: space-between;
    }

    .col-icon {
        text-align: right;

        img {
            width: 15px;
        }

        svg {
            width: 18px;
            height: auto;
            position: relative;
            top: 3px;
        }
    }

    h5.ant-typography {
        color: #404040;
        margin-bottom: 5px;
    }

    &:hover {
        background-color: #f2f2f2;
    }

    .ant-btn-link {
        color: var(--orange);
        box-shadow: none;
        border: none;
        padding: 0;

        &:disabled {
            color: var(--gray-light);
            span {
                text-decoration: none !important;
            }
        }

        &:hover:not(:disabled),
        &:focus {
            box-shadow: none;
            border: none;
            opacity: 0.8;
            color: var(--orange);
        }

        span {
            text-decoration: underline !important;
        }
    }

    .transaction-value {
        font-weight: 400;
        font-size: 18px;
        display: flex;
        justify-content: flex-end;
    }

    .transaction-status-circle {
        position: relative;
        left: -10px;
        top: 15px;
    }

    .transaction-status {
        display: flex;
        flex-direction: column;
        float: right;
        text-align: right;
        color: var(--gray-dark);
    }
}

.transaction-value-responsible {
    font-weight: 400;
    font-size: 18px;
}

.transaction-status-responsible {
    float: right;
    text-align: right;
    color: var(--gray-dark);
    display: flex;
}
