@import "../../utils/vars.scss";

.btn-back-next .ant-form-item-control-input-content {
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
}
.confirm-register {
    .purposes_exchange {
        margin-top: 16px;
    }

    .responsavel {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
        padding: 0;

        @media (max-width: $sm_max) {
            text-align: left;
        }
    }
}
