.choose-company-section {
  padding: 16px;
  // height: calc(100vh - 70px);
  // overflow-y: auto;
}

.choose-company {
  .container-card {
    .list-blank-company {
      .ant-form {
        display: flex;

        .ant-form-item {
          margin-right: 8px;
        }
      }

      .ant-card {
        cursor: pointer;
        width: 100%;
        margin-bottom: 8px;
      }
    }
  }
}

.companies-in-register-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alert-block {
  background-color: #c4fde7;
  padding: 5px;
  margin-bottom: 20px;
}

jdiv.wrap_a8a4._orientationRight_f14b.__jivoMobileButton {
  bottom: 10%;
}

@media only screen and (max-width: 584px) {
  .transaction-status-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;

  }

  .filter-selects {
    width: 100%;
  }

  .selects-col {
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
}