@import "../../../../utils/vars.scss";

.contacts-send {
  width: 100%;

  #button-add-contact-bottom {
    display: none;
  }

  @media (max-width: $sm_max) {
    #button-add-contact-top {
      width: 190px;
      display: none;
    }

    #button-add-contact-bottom {
      width: 190px;
      display: block;
    }
  }

  .contacts-send-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .contacts-list {
    margin-bottom: 0px;
    padding: 0px;

    .contact-receive-card {
      max-width: 1080px;
    }
  }
}
