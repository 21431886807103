@import '../../../utils/vars.scss';

.partner-item {
    background-color: white;
    border-radius: 16px;
    border: 1px solid #f0f0f0;
    margin-bottom: 10px;
}

.person-info-first-step {
    margin-bottom: 16px;

    @media (max-width: $sm_max) {
        text-align: center;
    }
}
