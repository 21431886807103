.contact-receive-card {
  width: 100%;
  // cursor: pointer;
  // display: flex;
  // margin-bottom: 5px;
  // padding: 20px;

  .card-header {
    // width: 80%;
    padding-top: 5px;
    // cursor: pointer;

    > div {
      font-weight: 500;
      color: #5a5a5a;
    }

    .icon-type {
      border-radius: 50%;
      background-color: #fafafa;
      border: 1px solid #eaeaea;
      padding: 10px 12px;

      svg {
        width: 25px;
        height: 25px;
        fill: #aaaaaa !important;
      }
    }

    .ant-divider {
      margin: 10px 0 20px 0;
    }

    .ant-typography {
      font-size: 18px !important;
    }

    .description {
      font-size: 16px;
    }
  }

  .card-body {
    .feature {
      color: #5a5a5a;
      font-weight: 600;
      margin-right: 5px;
    }

    .ant-table {
      width: 100%;
      padding: 0;
      // padding-bottom: 10px;
    }

    .ant-table-thead .ant-table-cell {
      background-color: transparent;
      color: #5a5a5a;
      padding: 0 10px;
      font-size: 12px;
    }

    .ant-table-tbody .ant-table-cell {
      background-color: #fafafa;
      padding: 0 10px;
      font-size: 14px;

      .feature {
        color: #5a5a5a;
        font-size: 10px;
      }
    }
  }

  .ant-card-body{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.company-card.disabled:hover {
  cursor: not-allowed;
}

.delete-icon{
  text-align: end;
  width: 20%;
  font-size: 18px;
  transition: 0.2s;

  > span:hover{
    transition: 0.2s;
    color: red;
    font-size: 18.5px;
  }
}