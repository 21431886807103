.efex-input {
    border: 1px solid #707070;
    border-radius: 3px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;

    .efex-input-prefix {
        height: 100%;
        margin-left: 6px;
        display: flex;
        align-items: center;
    }

    .efex-input-suffix {
        height: 100%;
        margin-right: 6px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    input {
        border: 0;
        margin: 5px;
        width: 100%;
    }

    input::placeholder {
        color: #c7c7c7;
        opacity: 1;
    }

    input:focus {
        outline: none;
    }

    input:disabled {
        background-color: #f5f5f5;
        color: rgba(0, 0, 0, 0.25);
    }
    input[disabled] {
        background-color: #f5f5f5;
        color: rgba(0, 0, 0, 0.25);
    }
}
