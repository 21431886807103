$xs-screen: 574px;
$sm-screen: 766px;

.receive-values {
    .white-button-green-hover {
        width: 120px;
    }
}

.switch-currency {
    width: 100%;
    max-width: none !important;
    text-align: center;
}

.actions-transaction {
    .ant-space-horizontal .ant-space-item {
        button {
            min-width: 140px;
        }
    }

    @media (max-width: $sm-screen) {
        .ant-space-horizontal {
            width: 100%;
            flex-direction: column;
            text-align: center;

            .ant-space-item {
                width: 100%;

                button {
                    width: 70%;
                }
            }
        }
    }
}

.select-currency {

    .ant-select-selector,
    .ant-select,
    .ant-select-selection-search,
    .ant-select-selection-placeholder {
        height: 32px !important;
        min-height: 32px !important;
    }
}

.stretch-column {
    display: flex;

    >div {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
    }
}

.switch-currency-btn {
    cursor: "pointer";

    .arrow-switch-icon {
        &[data-disabled="true"] {
            opacity: 0.4;
        }
    }
}

.schedule-exchange-button {
    min-width: 250px !important;
}

@media only screen and (max-width: 395px) {
    .is-scheduling-exchange-button {
        min-height: 50px;
    }
}