.contact-send {
  > .ant-row {
    padding-bottom: 15px;
  }

  .stretch-card {
    display: flex;

    .ant-card {
      flex: 0 0 100%;
    }
  }

  .contact-data {
    label {
      font-weight: 500;
      color: #9a9a9a;
      font-size: 12px;
    }

    label + div,
    label + span {
      color: #5a5a5a;
      font-size: 16px;
      padding-bottom: 5px;
    }

    .inline-value {
      padding: 5px 0;

      > * {
        display: inline;
      }

      label {
        margin-right: 10px;
      }
    }
  }
}
.bank-account-table {
  .feature {
    color: #5a5a5a;
    font-weight: 600;
    margin-right: 5px;
  }

  .ant-table {
    width: 100%;
    padding: 0;
    // padding-bottom: 10px;
  }

  .ant-table-thead .ant-table-cell {
    background-color: white;
    color: #5a5a5a;
    padding: 0 10px;
    font-size: 12px;
  }

  .ant-table-tbody .ant-table-cell {
    background-color: #fafafa;
    padding: 10px;
    font-size: 14px;

    .feature {
      color: #5a5a5a;
      font-size: 10px;
    }
  }
}
