@import "../../../utils/vars.scss";

.ant-table-thead .ant-table-cell {
  background-color: #f4f4f4 !important;
}

.tabs>.ant-tabs-nav {
  @media only screen and (max-width: 425px) {
    flex-direction: column-reverse;
  }
}

.filter-and-display-row {
  gap: 10px;
}

@media only screen and (max-width: 432px) {
  .filter-and-display-row {
    flex-direction: column-reverse;
    align-items: flex-end;
    gap: 0px;
  }
}

.selected-card {
  border: 2px solid #3498db !important;
  box-shadow: 0 0 10px rgba(52, 152, 219, 0.5);
  background-color: #ecf0f1 !important;
}

@media (max-width: $sm_max) {
  .ant-table-pagination {
    justify-content: center !important;
  }
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-item-link,
.ant-pagination-item {
  border: none !important;
}

.col-status {
  display: flex;
  flex-direction: row;

  .transaction-status-circle {
    float: left;
  }
}

.box_reason {
  display: flex;
  flex-direction: column;
  align-items: start;

  :nth-child(2) {
    min-width: 100%;
  }
}

.all-exchanges-and-close-exchange-container {
  display: flex;
}

@media only screen and (max-width: 1330px) and (min-width: 991px) {
  .all-exchanges-and-close-exchange-container {
    flex-direction: column;
  }

  .all-exchanges-and-close-exchange-container>.ant-col {
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .close-exchanges-componet {

    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}