$xs-screen: 574px;
$sm-screen: 766px;

.menu-exchange.steps-card {
  background: none;

  &.sticky {
    position: fixed;
    top: 10px;
    right: 85vw;
  }

  @media (max-width: $sm-screen) {
    &.sticky {
      right: 78vw;
    }
  }

  @media (max-width: $xs-screen) {
    &.sticky {
      position: relative;
      top: 0;
    }
  }

  .ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
    min-height: auto;
    font-weight: 500;
  }

  .ant-steps {
    @media (max-width: $xs-screen) {
      &.ant-steps-vertical {
        flex-direction: row !important;
      }

      .ant-steps-item {
        &.ant-steps-item-active {
          .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
            color: var(--green) !important;
          }
        }

        .ant-steps-item-container {
          flex-direction: column;
          text-align: center;

          .ant-steps-item-icon {
            order: 1 !important;
            margin: 0;
          }

          .ant-steps-item-content {
            order: 2 !important;

            .ant-steps-item-title {
              padding: 0;
              text-align: center;
              font-size: 13px;
              color: var(--gray-light) !important;
            }
          }
        }
      }
    }

    .ant-steps-item-container {
      display: flex;
      align-items: center;
      justify-content: end;
      margin-bottom: 12px;
      cursor: pointer;

      .ant-steps-item-title {
        color: transparent !important;
        padding: 0;
        padding-right: 10px;
        font-family: Fjalla One;
        font-size: 15px;
      }

      .ant-steps-item-content {
        order: 1;
      }

      .ant-steps-item-icon {
        border: 1px solid var(--green);
        width: 40px;
        height: 40px;
        order: 2;

        .ant-steps-icon {
          top: 2.5px;
          width: 20px;
          height: 20px;

          svg {
            height: 20px;
            width: 20px;
            color: var(--green);
          }
        }
      }

      &:hover {
        .ant-steps-item-title {
          color: var(--green) !important;
        }

        .ant-steps-item-icon {
          background-color: transparent;
          .ant-steps-icon svg {
            color: var(--green) !important;
          }
        }
      }
    }

    .ant-steps-item-active .ant-steps-item-icon {
      background-color: var(--green);

      .ant-steps-icon svg {
        color: white !important;
      }
    }
  }

  .ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
    display: none;
  }
}

.exchange-yellow .steps-card {
  .ant-steps {
    @media (max-width: $xs-screen) {
      .ant-steps-item.ant-steps-item-active {
        .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
          color: var(--orange) !important;
        }
      }
    }

    .ant-steps-item-container {
      .ant-steps-item-icon {
        border: 1px solid var(--orange) !important;
        background-color: transparent;
        .ant-steps-icon svg {
          color: var(--orange) !important;
        }
      }

      &:hover {
        .ant-steps-item-title {
          color: var(--orange) !important;
        }

        .ant-steps-item-icon {
          background-color: transparent;
          .ant-steps-icon svg {
            color: var(--orange) !important;
          }
        }
      }
    }

    .ant-steps-item-active .ant-steps-item-icon {
      background-color: var(--orange);
      .ant-steps-icon svg {
        color: white !important;
      }
    }
  }
}
