.card-pricereceive-simulate {
    max-height: 600px;
    min-height: 370px;
    border: none;

    &[data-simulation="true"] {
        background-color: #f47920;
    }

    &[data-simulation="false"] {
        background-color: #027f61;
    }

    h1.ant-typography,
    h2.ant-typography,
    h3.ant-typography,
    h4.ant-typography,
    h5.ant-typography,
    h6.ant-typography {
        color: white !important;
    }

    .title-box {
        text-align: center;
    }

    .box-exchange-value {
        width: 100%;
        flex-direction: column;
        text-align: center;

        h5.ant-typography {
            text-align: center;
        }

        .box-value {
            width: 100%;
            text-align: center;

            span {
                display: inline-block;
                color: black;
                background-color: white;
                border-radius: 3px;
                font-weight: bold;
                text-align: center;
                font-size: 20px;
                height: 40px;
                line-height: 40px;
                margin: 10px 0;
                width: 100%;
                min-width: 140px;
                max-width: 180px;
            }
        }

        .box-exchange-taxes {
            text-align: center;
            color: white;
            font-size: 10px;
            font-weight: 500;
            width: 100%;

            span {
                width: 100%;
                min-width: 140px;
                max-width: 180px;
                display: inline-block;

                p {
                    display: block;
                    width: 100%;
                    text-align: left;
                }
            }
        }
    }

    .box-currency-progress {
        text-align: center !important;

        .ant-progress-circle {
            color: white;
            margin-bottom: 10px;

            .ant-progress-text {
                color: white;
            }
        }

        .box-value {
            label {
                width: 100%;
                color: white;
                font-weight: 500;
            }

            div {
                width: 100%;

                span {
                    display: inline-block;
                    color: black;
                    background-color: #f8f8f8cc;
                    border-radius: 3px;
                    font-weight: bold;
                    text-align: center;
                    font-size: 15px;
                    height: 40px;
                    line-height: 40px;
                    margin-top: 5px;
                    min-width: 90px;
                    max-width: 100px;
                }
            }
        }
    }
}

.ant-form-item.send-day-form>.ant-row.ant-form-item-row>.ant-col.ant-form-item-label>label {
    color: white;
    margin-top: 12px;
}

.price-variation-info {
    padding: 10px 15px;
    font-size: 14px !important;
    color: var(--gray-very-dark) !important;
}

.tooltip {
    color: white;
}

@media only screen and (max-width: 991px) {
    .card-pricereceive-simulate {
        padding: 8px 10px
    }

    @media only screen and (max-width: 375px) {
        .card-pricereceive-simulate {
            min-height: 400px
        }
    }
}

.send-day-form .ant-form-item-control-input-content {
    transition: height 0.3s;
}

.send-day-form.has-error .ant-form-item-control-input-content {
    height: 30rem;
    /* ajuste conforme necessário */
}

@media only screen and (max-width: 689px) {

    .card-pricereceive-simulate[data-simulation="true"] {
        width: 230px;

    }

    .simulation-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .price-variation-info {
        width: 230px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1216px) {

    .card-pricereceive-simulate[data-simulation="true"] {
        width: max-content
    }
}

@media only screen and (min-width: 690px) and (max-width: 767px) {

    .card-pricereceive-simulate[data-simulation="true"] {
        width: 330px
    }
}