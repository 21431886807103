@import '../../../utils/vars.scss';

.fourth-step-upload {
    .ant-card.ant-card-bordered {
        margin-top: 8px;
    }
    .ant-row.ant-form-item {
        margin-bottom: 0;
    }
    .doc-card {
        padding: 16px 24px;
        @media (max-width: $sm_max) {
            margin-bottom: 8px;
        }
        .doc-item {
            padding: 8px 0px;
            border-bottom: 1px solid #e0e0e0;

            .ant-upload {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .doc-name {
                    @media (max-width: $sm_max) {
                        width: 50%;
                    }
                }
                .efex-grey-button {
  
                    min-width: 144px;
                
                    @media (max-width: $sm_max) {
                        width: 45%;
                    }
                    span {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
