.ant-card {
    padding: 12px;
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000029;

    /* width: 80%; */
    /* margin: 40px 0 0; */
    background-color: white;
}
.ant-card-body {
    padding: 0;

    h5.ant-typography {
        text-align: left;
    }
}

.ant-card-actions {
    li {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 345px) {

    h4.ant-typography {
      font-size: 18px;
    }
  }
