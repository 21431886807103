.transaction-status-circle {
  min-width: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: relative;
  top: 6px;
  margin-right: 10px;

  &.red {
    background-color: red;
  }

  &.yellow {
    background-color: orange;
  }

  &.green {
    background-color: green;
  }
}
