@import "../../../../utils/vars.scss";

.modal-exchange-approved {
  .ant-modal-content {
    width: 800px !important;
  }

  .ant-modal-body {
    text-align: center;
    align-items: center;
    padding: 50px 30px;
  }

  @media (max-width: $sm_max) {
    text-align: center;
  }

  .show-icon {
    text-align: center;
    padding: 40px 0;

    svg {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 150px;
      height: auto;
      color: var(--green);
    }
  }

  .description {
    width: 100%;
    font-size: 18px;
    text-align: center;
    display: inline-block;
    max-width: 500px;
  }

  &.person {
    .ant-typography {
      color: var(--green);
    }
  }
}
