.row-loading {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 100px;
}
.content-upload {
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	.ant-row {
		width: 100%;

		.responsaveis {
			.ant-row.ant-form-item {
				margin-top: 8px;
			}
		}
	}

	.ant-card {
		width: 100%;
	}
	.ant-row.ant-form-item {
		margin: 0;
	}

	.ant-btn.ant-btn-primary {
		margin-top: 16px;
	}

	.ant-form-item-control-input-content {
		display: flex;
		align-items: center;
		& > span {
			width: 100%;
			cursor: pointer;
		}

		.ant-upload {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		
			.efex-grey-button {
				min-width: 144px;
			}
		
			p {
				width: 80%;
			}
		}

		.ant-upload-select:hover {
			.ant-btn {
				border-color: var(--green);
				color: var(--green);
			}
		}
	}
}

.document-title {
	text-transform: lowercase;

}

.document-title::first-letter {
	text-transform: uppercase;
}