@import "../../../../utils/vars.scss";

.send-values-approved .stretch-column > div {
  display: block !important;
}

.send-values-approved {
  .stretch-column {
    display: flex;

    > div {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
    }
  }

  .actions-transaction {
    .ant-space-horizontal .ant-space-item {
      button {
        width: 160px;
      }
    }

    @media (max-width: $sm_max) {
      .ant-space-horizontal {
        width: 100%;
        flex-direction: column;
        text-align: center;

        .ant-space-item {
          width: 100%;
          button {
            width: 50%;
          }
        }
      }
    }
  }
}
