.person-profile {
    .stretch-card {
        display: flex;

        .ant-card {
            flex: 0 0 100%;
        }
    }

    .exchange-buttons {
        padding: 50px 0;
        width: 100%;

        .ant-col:first-child {
            text-align: right;
            padding-right: 100px;
        }

        .ant-col:last-child {
            text-align: left;
            padding-left: 100px;
        }
    }

    .person-data {
        label {
            font-weight: 500;
            color: #9a9a9a;
            font-size: 12px;
        }

        label + div,
        label + span {
            color: #5a5a5a;
            font-size: 16px;
            padding-bottom: 5px;
        }

        .inline-value {
            padding: 5px 0;

            > * {
                display: inline;
            }

            label {
                margin-right: 10px;
            }
        }
    }

    .contas-receber-brasil {
        .ant-typography {
            width: 100%;
            margin-bottom: 8px;
        }

        p {
            font-size: 13px;
            width: 100%;
        }
    }
}

.seeAllButtonPerson {
    text-align: center;
    cursor: pointer;
    color: var(--green);
    transition: 0.6s;

    &:hover {
        transition: 0.6s;
        text-decoration: underline;
    }
}

@media only screen and (max-width: 584px) {
    .transaction-status-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
    }

    .filter-selects {
        width: 100%;
    }

    .selects-col {
        width: 100%;
        display: flex;
        align-items: flex-end;
    }
}
