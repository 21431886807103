@import "../../../utils/vars.scss";

.ant-layout-header {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  width: 100%;
  height: 66px;
  padding: 0 16px;

  .ant-menu {
    width: 100%;
  }
}

.avatar-container {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 10px;
}

.logo,
.internal-logo {
  float: left;
  width: 124px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background-image: url("../../../common/assets/efexhub_logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.internal-logo {
  background-image: url("../../../common/assets/efexhub_logo_icon.png");
  width: 38px;
  margin: 0 0 -4px;
}

.user-menu {
  border: 1px solid #c1c1c1;
  background-color: white;
  width: 200px;
  padding: 10px;
  border-radius: 5px;

  .triangle {
    position: relative;
    top: -16px;
    left: 160px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
}

.user-menu-name {
  font-family: Roboto;
  font-size: 12px;
  color: #575757;
}

.background-efexhub-header {
  width: 100%;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.drawer-menu-mobile {
  .ant-drawer-header {
    padding: 12px;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: var(--white);
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: var(--green);
  }
}

.main-menu-mobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .content-btn-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icons-menu {
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.item-menu {
  cursor: pointer;
}

@media (max-width: 990px) and (min-width: 535px) {
  .notification-modal {
    left: 34%;
    transform: translateX(-50%);
  }
}