@import "../../../utils/vars.scss";

.register-base-layout {
    width: 100%;

    .titulo-desc {
        margin-bottom: 10px;
    }
}
.row-steps-content {
    padding: 32px 16px;
    width: 100%;
    flex-wrap: initial;

    @media (max-width: $sm_max) {
        flex-wrap: wrap;

        .steps-card {
            display: none;
        }
    }

    .steps-card-mobile {
        display: none;
        margin-bottom: 24px;
        .ant-steps-item {
            padding: 0;

            .ant-steps-item-content{
                display: none;
            }
        }
        .ant-steps-item.ant-steps-item-process.ant-steps-item-active {
            margin-top: -3px;
            .ant-steps-item-icon {
                width: 40px;
                height: 40px;
                .ant-steps-icon {
                    top: 2.5px;
                }
            }
        }
        @media (max-width: $sm_max) {
            display: flex;
        }
    }

    .col-content {
        // overflow-y: auto;
        // overflow-x: hidden;
        // height: calc(100vh - 98px);

        @media (max-width: $sm_max) {
            height: auto;
        }
    }
}
