@import "../../utils/vars.scss";

.home-page {
    padding: 16px;
    // overflow-y: auto;
    // height: calc(100vh - 66px);

    h4.ant-typography {
        color: #404040;
    }

    .row-loading {
        min-height: 60px;
    }
}

.button-owner-responsible {
    width: 80%;
    height: 45px;
    border: none;
    border-radius: 10px;
    color: white;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;

    div {
        font-family: Fjalla One;
    }

    &.company {
        background-color: var(--orange);

        &:hover {
            color: gray;
        }
    }

    &.person {
        background-color: var(--green);

        &:hover {
            color: gray;
        }
    }

    img {
        width: 90px;
        border: solid 1px white;
    }

    svg {
        width: 90px;
        height: auto;
        color: white;
    }

    @media (max-width: $sm_max) {
        img {
            width: 20px;
            border: solid 1px white;
        }

        svg {
            width: 20px;
            height: auto;
            color: white;
        }
    }
}

@media only screen and (max-width: 584px) {
    .transaction-status-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;

    }

    .filter-selects {
        width: 100%;
    }

    .selects-col {
        width: 100%;
        display: flex;
        align-items: flex-end;
    }
}