@import "../../utils/vars.scss";

.site-layout-content {
    width: 100%;
    background: #f5f5f5;
    // height: auto;

    @media (max-width: $sm_max) {
        height: auto;
    }

    .col-steps {
        max-width: 259px;
        @media (max-width: $sm_max) {
            max-width: inherit;
        }
    }

    .col-content {
        padding-left: 16px;

        @media (max-width: $sm_max) {
            padding-left: 0;
        }
    }
}
