.register-row .ant-col:first-child .ant-typography {
  font-size: 22px;
}

.register-row .ant-col:first-child .ant-list-item {
  padding: 8px 0;
}

.register-row .ant-col:last-child {
  overflow-y: hidden;
  width: 100%;
}

.register .content {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
}

.register .ant-input {
  height: 40px;
}

.register .ant-btn {
  font-size: 22px;
  width: 100%;
  height: 42px;
}

.ant-form-item-control-input-content>.ant-input.cpf-input {
  color: #c7c7c7;
}

.ant-form-item-control-input-content>.ant-input.cpf-input.focused {
  color: black;
}

.ant-form-item-control-input-content>.ant-input.cpf-input.filled {
  color: black;
}