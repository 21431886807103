.type-selector-input {
  padding: 0 !important;
  p {
    margin-bottom: 6px;
  }

  .ant-row {
    width: 100%;
    flex-wrap: nowrap;
    height: 31px;
    margin-bottom: 16px;

    .ant-form-item {
      outline: none;
    }

    // .ant-form-item:nth-child(1) {
    //   width: 25%;
    //   .ant-select-selector {
    //     background-color: #F2F2F2;
    //     border-top-right-radius: 0;
    //     border-bottom-right-radius: 0;
    //   }
    // }

    .ant-form-item:nth-child(2) {
      margin-left: -2px;
      .ant-select-selector {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
