@import "./utils/vars.scss";

#root {
    height: 100%;
}
html {
    scroll-behavior: smooth;
    height: auto;
    // overflow: hidden;
    height: 100%;

    @media (max-width: $sm_max) {
        overflow: inherit;
    }
}

body {
    background-color: #f5f5f5;
    height: 100%;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}
p {
    margin-bottom: 4px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.ant-layout {
    background: #f5f5f5;
}

.ant-layout-content {
    width: 100%;
    min-height: 76vh;
}

.container {
    max-width: 1320px;
    margin: auto;
    @media (max-width: 1280px) {
        max-width: 1080px;
    }
}
// .ant-menu-overflow .ant-menu .ant-menu-root .ant-menu-horizontal .ant-menu-light {
//     background-color: white;
// }

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    background-color: white;
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography {
    margin: 0;
    color: #f99d1c;
    font-family: Fjalla One;
}

.ant-btn:hover,
.ant-btn:focus {
    border-color: var(--green);
    color: var(--green);
}
.ant-btn-primary,
.ant-btn-primary:active {
    background: var(--green);
    background-color: var(--green);
    outline: none;
    border: none;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
    /* background: var(--green); */
    color: var(--white);
    background-color: var(--green-light);
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: var(--green);
    border-color: var(--green);
}
.ant-steps-item-icon .ant-steps-icon {
    color: var(--green);
}
.ant-steps-item-finish .ant-steps-item-icon {
    border-color: var(--green);
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: var(--green);
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: var(--green);
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ant-menu {
    background-color: white;
    color: #fff;
    font-weight: 600;
}

.ant-menu-horizontal {
    border: 0;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: #fff;
    border-bottom: 2px solid #fff;
}

.ant-menu-horizontal > .ant-menu-item a {
    color: #7b7b7b;
    font-family: Fjalla One;
}

.ant-menu-horizontal > .ant-menu-item a:hover {
    color: #7b7b7b;
}

.ant-input-affix-wrapper {
    border: 1px solid #707070;
    border-radius: 3px;
    height: 38px;
}

.ant-input-affix-wrapper {
    input {
        border: 1px o;
        margin: 6px;
        font: Roboto;
        width: 100%;
        background-color: #fff;
    }

    input::placeholder {
        opacity: 1;
        color: #c7c7c7;
    }
}

.ant-input-affix-wrapper-focused {
    box-shadow: none;
}

.ant-input {
    border: 1px solid #707070;
    border-radius: 3px;
    background-color: #fff;
    padding: 6px;
}

.ant-card .ant-input[disabled] {
    border-color: #707070;
    background-color: #f5f5f5;
}

.ant-input:focus,
.ant-input-focused {
    box-shadow: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #707070;
    border-radius: 4px;
    min-height: 36px;
    padding: none;
}
.ant-card {
    .ant-select-selector {
        min-height: 36px;
        padding: none;
    }

    .ant-divider {
        margin: 10px 0;
    }

    .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    .ant-input {
        border: 1px solid #707070;
        border-radius: 3px;
        background-color: #fff;
        padding: 6px;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid #707070;
    }
    .remove-right-border {
        .ant-input {
            border-left: 0;
        }
        .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            background-color: #f2f2f2;
        }
    }
}
.custom-icon {
    width: 18px;
}

.ant-steps-item-active {
    .custom-icon {
        filter: contrast(100) brightness(0) invert(1);
    }

    .ant-steps-item-container:hover {
        .custom-icon {
            filter: none;
        }
    }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
    border-color: #707070;
}

.ant-input-affix-wrapper input {
    border: none;
}

.ant-select-selection-item {
    display: flex;
    align-items: center;
}

.ant-select-selection-placeholder {
    display: flex;
    align-items: center;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 3px solid #7b7b7b;
    bottom: 15px;
}

.ant-menu-title-content {
    color: #7b7b7b;
    font-family: Fjalla One;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #707070;
}

.grey-title {
    color: #404040 !important;
}

.transactions-select {
    width: auto !important;
    div {
        border: none !important;

        span {
            color: #404040 !important;
        }
    }

    span {
        span {
            svg {
                color: #7b7b7b;
            }
        }
    }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #404040 !important;
    background-color: white !important;
}

.left-right-layout {
    height: 100vh;
    flex-direction: row;

    .content {
        flex-direction: row;
    }

    .left-container {
        width: 50%;
        background-color: var(--white);
        height: 100%;
        background-image: url("./common/assets/login_img.png");
        background-size: cover;
    }

    .right-container {
        background-color: var(--white);
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 30px;

        @media (max-width: $sm_max) {
            width: 100%;
        }
    }

    .ant-layout {
        align-items: flex-start;
    }

    .welcome {
        font-size: 32px;
        font-family: "Fjalla One", sans-serif;
        margin-bottom: 40px;
        color: #7b7b7b;
    }

    @media (max-width: $sm_max) {
        .left-container {
            display: none;
        }
    }
}

.register-country-phone {
    padding: 0;
    
}