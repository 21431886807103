.partner-item {
    background-color: white;
    border-radius: 16px;
    border: 1px solid #f0f0f0;
    margin-bottom: 10px;
}

.subtitle {
    color: var(--yellow);
    font-weight: 600;
}