.change-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.change-password-content {
    width: 100%;
    max-width: 400px;
    padding: 20px;
}