* {
    font-family: "Roboto", sans-serif;
}
:root {
    --green: #105151;
    --green-light: #107f61;
    --blue: #3186b8;
    --white: #fff;
    --orange: #f99d1c;
    --gray-light: #c1c1c1;
    --gray-dark: #7b7b7b;
    --gray-very-dark: #474747;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@font-face {
    font-family: "Roboto";
    src: local("Roboto"),
        url(./common/assets/fonts/Roboto/Roboto-Regular.ttf) format("opentype");
}

@font-face {
    font-family: "Roboto";
    font-weight: 500;
    src: local("Roboto"),
        url(./common/assets/fonts/Roboto/Roboto-Medium.ttf) format("opentype");
}

@font-face {
    font-family: "Roboto";
    font-weight: 900;
    src: local("Roboto"),
        url(./common/assets/fonts/Roboto/Roboto-Bold.ttf) format("opentype");
}

@font-face {
    font-family: "Fjalla One";
    src: local("Fjalla One"),
        url(./common/assets/fonts/Fjalla_One/FjallaOne-Regular.ttf)
            format("opentype");
}
