.idented-box {
    border-left: solid 5px;
    margin-left: 20px;
    padding-left: 15px;
    margin-bottom: 10px;
}

.idented-box-orange {
    @extend .idented-box;
    border-color: var(--yellow);
}

.idented-box-green {
    @extend .idented-box;
    border-color: var(--green);
}