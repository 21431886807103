.people {
    padding: 16px;
    // overflow-y: auto;
    // height: calc(100vh - 66px);
}

.people-flow-default {

    h1.ant-typography,
    h2.ant-typography,
    h3.ant-typography,
    h4.ant-typography,
    h5.ant-typography,
    h6.ant-typography {
        color: var(--green);
    }

    .people-empty {
        padding: 24px;

        p {
            text-align: center;
            color: var;
            font-size: 18px;
        }
    }

    .person-list {
        margin-bottom: 16px;

        .people-in-register-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #e0e0e0;
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
    }
}

@media only screen and (max-width: 584px) {
    .transaction-status-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;

    }

    .filter-selects {
        width: 100%;
    }

    .selects-col {
        width: 100%;
        display: flex;
        align-items: flex-end;
    }
}