.efex-grey-button {
    background-color: #7b7b7b;
    border-radius: 5px;
    font-size: 14px;
    font-family: Roboto;
    color: #fff;
    border: none;
    padding-bottom: 5px;
    font-weight: 500;
    padding-top: 5px;
    height: 35px;
    min-width: 80px;
    cursor: pointer;
    margin-top: 8px;

    span {
        margin-right: 10px;
    }
}

.white-button {
    border: 1px solid #707070;
    background-color: white;
    font-family: Roboto;
    font-weight: 500;
    color: black;
    font-size: 13px;
    border-radius: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
    min-width: 80px;
    height: 35px;
    cursor: pointer;
    margin-top: 8px;
}

.white-button-orange-hover {
    @extend .white-button;
}

.white-button-green-hover {
    @extend .white-button;
}

.white-button-orange-hover:hover {
    border-color: #f47920;
    color: #f47920;
}

.white-button-green-hover:hover {
    border-color: #115151;
    color: #115151;
}

.efex-green-button {
    background-color: var(--green);
    color: var(--white);
}

.efex-orange-button {
    background-color: var(--orange);
    color: #404040;
}

.efex-orange-button,
.efex-green-button {
    border: none;
    border-radius: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
    font-family: Roboto;
    cursor: pointer;
    min-width: 80px;
    height: 35px;
    font-size: 13px;
    font-weight: 500;
    margin-top: 8px;
}

.efex-orange-button:disabled,
.efex-green-button:disabled,
.efex-grey-button:disabled,
.white-button-orange-hover:disabled,
.white-button-green-hover:disabled {
    background-color: #d7d7d7;
    color: #404040;
    border: none;
    cursor: auto;
}

.efex-add-circle-button {
    background-color: transparent;
    border: 1px solid var(--green);
    color: var(--green);
    min-width: 0;
    min-height: 0;
    height: 25px !important;
    width: 25px !important;

    .anticon.anticon-plus {
        position: relative;
        // top: -2px;

        svg {
            width: 16px;
        }
    }

    &:hover,
    &:focus {
        color: var(--green);
        opacity: 0.8;
    }

    &:disabled {
        border: 1px solid var(--gray-light) !important;
        color: var(--gray-light) !important;
    }
}
