@import "../../../utils/vars.scss";

@media (max-width: $sm_max) {
    .ant-modal-content {
        width: 100vw;
        // height: 100vh;
    }

    .ant-modal {
        margin: 0;
        padding: 0;
        top: 0;

        .ant-modal-footer {
            height: 80px;

            div{
                justify-content: start !important;
            }
        }
    }
}

.modal-person {
    .ant-modal-title {
        color: var(--green);
    }
}
.modal-company {
    .ant-modal-title {
        color: var(--orange);
    }
}
.ant-modal-title {
    font-size: 18px;
    font-family: Fjalla One;
}

.ant-modal-close {
    background-color: black;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    top: 8px;
    right: 10px;
}

.ant-modal-close-x {
    line-height: 0;
    color: white;
    width: 0;
    height: 0;
    display: inline;
}
.ant-modal-content {
    border-radius: 10px;
}

.ant-modal-header {
    border-radius: 10px;
    border: none;
}

.ant-modal-footer {
    border: none;
}


.info{
    margin-top: 15px;
    display: flex;
    align-items: baseline;
    gap: 5px;
}