.steps-card {
    border: none;
    background: none;
    width: 101%;

    .clickable{
        cursor: pointer;
    }

    .ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
        min-height: auto;
        font-weight: 500;
    }

    .ant-steps {
        .ant-steps-item-container {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
        }
        .ant-steps-item-title {
            padding: 0;
        }
    }

    .ant-steps-item-icon {
        width: 40px;
        height: 40px;
        .ant-steps-icon {
            top: 2.5px;
        }
    }
    .ant-steps-vertical
        > .ant-steps-item:not(:last-child)
        > .ant-steps-item-container
        > .ant-steps-item-tail {
        display: none;
    }

    .ant-steps-item-finish
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title {
        color: var(--green);
        font-weight: 500;
    }
}

.steps-card.steps-yellow, .steps-card-mobile.steps-yellow {
    .ant-steps-item-finish
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title {
        color: var(--orange);
    }

    .ant-steps-item-finish .ant-steps-item-icon {
        border-color: var(--orange);
    }

    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
        background-color: var(--orange);
        border-color: var(--orange);
    }

    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
        color: var(--orange);
    }
}

.sticky {
    position: fixed;
    top: 5px;
    max-width: 22%;
}
