.blank-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ccc;

    img {
        width: 80px;
        opacity: 10%;
    }
}