@import "../../utils/vars.scss";

$small-screen: 574px;

.exchange {
    display: flex;
    padding-right: 90px;

    @media (max-width: $small-screen) {
        padding-right: 0;
    }

    h1.ant-typography,
    h2.ant-typography,
    h3.ant-typography,
    h4.ant-typography,
    h5.ant-typography,
    h6.ant-typography {
        color: var(--green);
        font-size: 18px;
    }

    .exchange-sidemenu {
        // height: 100vh;
        margin-top: 40px;
        text-align: center;
        @media (max-width: $small-screen) {
            padding: 0 15px;
        }
    }

    .exchange-content {
        // width: 100%;
        padding: 24px;
        // height: calc(100vh - 66px);
        // overflow-y: auto;

        @media (max-width: $sm_max) {
            height: auto;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: var(--green);
        }
        .ant-tabs-tab:hover {
            color: var(--green);
        }
        .ant-tabs-ink-bar {
            background: var(--green);
        }

        .icon-type {
            border-radius: 50%;
            background-color: var(--gray-light);
            border: None;
            padding: 8px 10px;
            width: 45px;

            svg {
                width: 25px;
                height: 25px;
                fill: white !important;
                position: relative;
                top: 2px;
            }
        }
    }
}

.exchange-yellow {
    h1.ant-typography,
    h2.ant-typography,
    h3.ant-typography,
    h4.ant-typography,
    h5.ant-typography,
    h6.ant-typography {
        color: var(--orange);
    }

    .exchange-content {
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: var(--orange);
        }
        .ant-tabs-tab:hover {
            color: var(--orange);
        }
        .ant-tabs-ink-bar {
            background: var(--orange);
        }
    }

    .efex-add-circle-button,
    .efex-add-circle-button:hover,
    .efex-add-circle-button:focus {
        border: 1px solid var(--orange);
        color: var(--orange);
    }

    .idented-box {
        border-color: var(--orange);
    }
}
