@import "../../utils/vars.scss";

.legal-person-extra {
    .titulo-desc {
        // width: 50%;
    }
    .row-steps-dados-extra {
        flex-wrap: initial;
    }
    .row-empresa-endereco {
        flex-wrap: initial;
        padding-top: 32px;

        @media (max-width: $sm_max) {
            flex-wrap: wrap;
        }
        .ant-col {
            width: 100%;

            .obs {
                margin-top: 16px;
            }
        }
    }
    .ant-form-item-control-input-content {
        justify-content: space-between;
        display: flex;
    }
    .btn-back-next {
        width: 100%;
        margin-top: 20px;
        text-align: right;
    }
    .purpose-col {
        max-width: 50%;

        @media (max-width: $sm_max) {
            max-width: 100%;
        }
        .ant-select-multiple {
            .ant-select-selector {
                margin-top: 16px;
                margin-bottom: 16px;
            }
        }
        .operation_mode {
            .ant-select-multiple {
                .ant-select-selector {
                    margin: 0;
                }
            }
        }
    }
}

// .legal-person .ant-row {
//     flex-flow: initial;
// }
