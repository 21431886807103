.grid-config {
    display: flex;
    justify-content: space-between;
    width: 60px;

    .grid-icon svg {
        height: 20px;
        width: 20px;
        cursor: pointer;
        
        path {
            fill: #c3c3c3;
        }
    }
}

.grid-item-selected {
    path {
        fill: #404040 !important;
    }
}