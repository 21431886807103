.ant-input-affix-wrapper.cpf-input>.ant-input {
  color: #c7c7c7;
}

.ant-input-affix-wrapper.cpf-input>.ant-input.filled {
  color: black;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-focused.cpf-input>.ant-input {
  color: black;
}