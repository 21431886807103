.ant-checkbox {
    border: 1px solid #707070 !important;
    border-radius: 2px !important;
    background-color: white !important;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner:hover {
    border: 1px solid #707070 !important;
    background-color: #F47920 !important;
}
.ant-checkbox-checked::after {
    border: 1px solid #F47920 !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 1px solid #707070 !important;
}
