.person-card {
  box-shadow: none;
  background: none;
  background-color: transparent;
  border: none;
  border-radius: 0;

  .ant-typography {
    font-size: 14px;
  }

  width: 100%;
  cursor: pointer;
  margin-bottom: 5px;

  .container-status {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: flex-end;
  }
}

.company-card.disabled:hover {
  cursor: not-allowed;
}

.person-name {
  font-size: 18px;
  font-family: Fjalla One;
  color: #838282;
  text-transform: uppercase;
}

.person-infos {
  width: 90%;
  padding: 4px;
}

@media only screen and (max-width: 991px) {
  .delete-column-mobile {
    margin-left: auto;
  }
}

@media only screen and (max-width: 345px) {
  .person-name {
    font-size: 16px;
  }

}