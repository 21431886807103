.efex-orange-link {
    color: #F47920;
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 500;
}

.efex-green-link {
    color: var(--green);
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 500;
}